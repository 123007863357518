import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  
  @Target submitRowTarget: HTMLButtonElement;

  connect() {

    this.element.querySelectorAll('input, select, textarea').forEach(input => {
      input.addEventListener('input', this.showSubmitButton.bind(this));
    });
  }

  showSubmitButton() {
    this.submitRowTarget.classList.remove('tw-hidden');
  }
}
